<template>
  <div
    v-if="eventLoading"
    class="event event--loading"
  >
    <Spinner />
  </div>
  <div
    v-else-if="eventFound && !event.isHidden"
    :class="[
      'event',
      { 'event--has-betslip': hasBetslip }
    ]"
  >
    <EventScoreboard
      :is-prematch="isPrematch"
      :is-live="isLive"
      :starts-at="startsAt"
      :event="event"
      :period-label="statusLabel"
      :team-in-possession="event?.teamInPossession"
      :sport-id="event?.sportId"
    />
    <EventMarkets
      :event="event"
      :display-navigation="displayPlayerStatistics"
      v-if="selectedEventNavigationTab === 'event-odds' || !displayPlayerStatistics"
    />
    <PlayerStatistics
      v-if="displayPlayerStatistics && selectedEventNavigationTab === 'player-statistics'"
      :event="event"
    />
    <Betslip
      :event-id="event.id"
      v-if="hasBetslip"
    />
  </div>
  <div
    class="no-event-found"
    v-else-if="event.isHidden"
  >
    <NoResultsIcon />
    Event is hidden
  </div>
  <div
    class="no-event-found"
    v-else
  >
    <NoResultsIcon />
    No event found
  </div>
</template>

<script>
import { computed, watch, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { includes } from 'lodash';
import * as Model from '@/models';
import { useOnlineStatus } from '@/composables';
import { NoResultsIcon } from '@/components/svg';
import Event from '@/services/helpers/event';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import Ultimate from '@/services/helpers/ultimate';
import Spinner from '@/components/common/Spinner';
import Betslip from '@/components/betslip/Betslip';
import EventScoreboard from './EventScoreboard';
import EventMarkets from './EventMarkets';
import PlayerStatistics from './player-statistics/PlayerStatistics';

export default {
  components: {
    Spinner,
    EventScoreboard,
    EventMarkets,
    NoResultsIcon,
    Betslip,
    PlayerStatistics,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const id = computed(() => route.params.id);

    const event = computed(() => {
      const data = store.getters.event;
      const hasDetails = !!Event.findEventDetails(data);
      switch (data?.sportId) {
      case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
        return { ...Model.SoccerEvent(data), hasDetails };
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return { ...Model.BasketballEvent(data), hasDetails };
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return { ...Model.FootballEvent(data), hasDetails };
      case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
        return { ...Model.HockeyEvent(data), hasDetails };
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return { ...Model.BaseballEvent(data), hasDetails };
      case '6093d1bf-d572-486b-af56-96287ee0e865':
        return { ...Model.UltimateEvent(data), hasDetails };
      default:
        return { ...Model.Event(data), hasDetails };
      }
    });
    const eventLoading = computed(() => store.getters.eventLoading);
    const eventFound = computed(() => store.getters.eventFound);
    const eventHidden = computed(() => store.getters.eventHidden);

    const livePeriods = computed(() => {
      switch (event.value.sportId) {
      case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
        return Soccer.getLivePeriods(event.value);
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return Basketball.getLivePeriods(event.value);
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return Football.getLivePeriods(event.value);
      case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
        return Hockey.getLivePeriods(event.value);
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return Baseball.getLivePeriods(event.value);
      case '6093d1bf-d572-486b-af56-96287ee0e865':
        return Ultimate.getLivePeriods(event.value);
      default:
        return [];
      }
    });
    const isPrematch = computed(() => Event.isPrematch(event.value));
    const isLive = computed(() => Event.isLive(event.value));
    const isCanceled = computed(() => Event.isCanceled(event.value));
    const startsAt = computed(() => Event.parseDateTime(event.value));
    const periodLabel = computed(() => {
      if (isLive.value && !includes(livePeriods.value, event.value.period)) return 'Live';
      switch (event.value.sportId) {
      case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
        return Soccer.getPeriodLabel(event.value);
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return Basketball.getPeriodLabel(event.value);
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return Football.getPeriodLabel(event.value);
      case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
        return Hockey.getPeriodLabel(event.value);
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return Baseball.getPeriodLabel(event.value);
      case '6093d1bf-d572-486b-af56-96287ee0e865':
        return Ultimate.getPeriodLabel(event.value);
      default:
        return 'N/A';
      }
    });
    const statusLabel = computed(() => {
      if (isCanceled.value) return 'Canceled';
      if (isLive.value) return periodLabel.value;
      return startsAt.value;
    });

    const selectedEventNavigationTab = computed(() => store.getters.selectedEventNavigationTab);

    const displayPlayerStatistics = computed(() => (event.value?.sportId === '841e188b-0dcf-4f5f-974f-aa52c8cec95b' || event.value?.sportId === 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f' || event.value?.sportId === '4e8eca10-6afa-44ed-af77-42414ec45cb3') && !isPrematch.value);

    const hasBetslip = computed(() => store.getters.betslipEnabled && event.value.hasSgp);

    watch(
      id,
      () => {
        if (!id.value) {
          router.push({ name: 'home' });
          return;
        }
        store.dispatch('setSelectedEventNavigationTab', 'event-odds');
        store.dispatch('loadEvent', id.value);
      },
      { immediate: true },
    );
    onUnmounted(() => {
      store.dispatch('unloadEvent');
    });

    useOnlineStatus({
      onReconnect() {
        store.dispatch('reloadEvent');
      },
    });

    return {
      event,
      eventLoading,
      eventFound,
      eventHidden,

      isPrematch,
      isLive,
      isCanceled,
      startsAt,
      periodLabel,
      statusLabel,

      displayPlayerStatistics,
      selectedEventNavigationTab,

      hasBetslip,
    };
  },
};
</script>

<style lang="scss">
.event {
  display: grid;
  grid-template-columns: 368px 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1.5rem 1rem;

  @media screen and (max-width: 949px) {
    grid-template-columns: 1fr;
    margin: 56px 0 1.5rem;

    &.event--has-betslip {
      margin: 56px 0 calc(1.5rem + 60px);
      padding-bottom: 50px;

      .event-markets-footer {
        bottom: 60px;
      }
    }
  }

  @media screen and (min-width: 950px) and (max-width: 1519px) {
    &.event--has-betslip {
      grid-template-columns: 1fr 368px;
      grid-template-rows: auto auto;

      .event-scoreboard {
        grid-column: 1;
      }
      .event-markets {
        grid-column: 1;
      }
      .betslip {
        grid-column: 2;
        grid-row: 1/3;
      }
    }
  }

  @media screen and (min-width: 1520px) {
    grid-template-columns: 368px 1fr;

    &.event--has-betslip {
      grid-template-columns: 368px 1fr 368px;
    }

    max-width: 1520px;
    margin: 1.5rem auto;
    padding: 0 1rem;
  }

  &.event--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70dvh;
  }
}

.no-event-found {
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #A9A9A9;
  font-size: 1rem;
  gap: 0;
  width: 25rem;
  margin: 0 auto;
  text-align: center;
  padding-top: 108px;

  svg {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
</style>
