<template>
  <div
    ref="container"
    :class="[
      'event-list',
      { 'event-list--desktop': !isMobile },
    ]"
  >
    <div
      class="event-list__content"
      v-if="!loading && groupedEventsByDate.length"
    >
      <div
        class="event-list__group"
        v-for="(eventsDay, index) in groupedEventsByDate"
        :key="index"
      >
        <div
          ref="groupHeaders"
          :class="[
            'event-list__group-header',
            { 'event-list__group-header--expanded': showMenu },
            { 'pinned': groupHeadersPinned[index] },
          ]"
        >
          {{ eventsDay.date }}
        </div>
        <EventCard
          v-for="event in eventsDay.events"
          :key="event.id"
          :event="event"
          :markets="markets"
        />
      </div>
    </div>

    <div
      v-if="loading"
      ref="container"
      class="event-list__content event-list__content--loading"
    >
      <Spinner />
    </div>

    <div
      v-if="!loading && !groupedEventsByDate.length"
      ref="container"
      class="event-list__content event-list__content--empty"
    >
      <div
        v-if="searchQuery"
        class="event-list__content-message"
      >
        <NoResultsIcon />
        No search results
      </div>
      <div
        v-else
        class="event-list__content-message"
      >
        <NotFoundIcon />
        No events for this sport at the moment
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import {
  map, chain, zipObject, orderBy, replace, filter,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { useBreakpoints, useScrollMenu } from '@/composables';
import * as Model from '@/models';
import { NotFoundIcon, NoResultsIcon } from '@/components/svg';
import Spinner from '@/components/common/Spinner';
import eventHelpers from '@/services/helpers/event';
import EventCard from './EventCard';

const PIN_SENSITIVITY_MOBILE = 50;
const PIN_SENSITIVITY_DESKTOP = 148;

export default {
  components: {
    NotFoundIcon,
    NoResultsIcon,
    Spinner,
    EventCard,
  },
  props: {
    markets: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    const { isMobile } = useBreakpoints();
    const loading = computed(() => store.getters.eventsLoading);
    const isEventHidden = ((event) => event.isHidden ?? !!event.operatorEventUndisplaysByEventId?.nodes?.length);

    const events = computed(() => filter(map(store.getters.events, (data) => {
      const hasDetails = !!eventHelpers.findEventDetails(data);
      switch (data.sportId) {
      case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
        return { ...Model.SoccerEvent(data), hasDetails };
      case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
        return { ...Model.BasketballEvent(data), hasDetails };
      case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
        return { ...Model.FootballEvent(data), hasDetails };
      case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
        return { ...Model.HockeyEvent(data), hasDetails };
      case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
        return { ...Model.BaseballEvent(data), hasDetails };
      case '6093d1bf-d572-486b-af56-96287ee0e865':
        return { ...Model.UltimateEvent(data), hasDetails };
      default:
        return { ...Model.Event(data), hasDetails };
      }
    }), (event) => !isEventHidden(event)));
    const { showMenu } = useScrollMenu({
      mobileOnly: true,
    });

    const groupedEventsByDate = computed(() => (
      chain(events.value)
        .groupBy((e) => replace(format(zonedTimeToUtc(e.startsAt, 'UTC'), 'EEEE, MMMM dd, yyyy'), `, ${new Date().getFullYear()}`, ''))
        .toPairs()
        .map((e) => zipObject(['date', 'events'], e))
        .value()
    ));

    const search = ref('');
    const searchEvents = () => {
      store.dispatch('setEventsFilters', { search: search.value });
    };
    const searchQuery = computed(() => store.getters.searchQuery);

    const liveToggled = computed(() => store.getters.eventsFilters.live);
    const toggleLiveFilter = () => {
      store.dispatch('setEventsFilters', { live: !liveToggled.value });
      store.dispatch('setEventsPagination', !liveToggled.value);
    };
    const selectedDateRange = computed(() => store.getters.selectedDateRange);
    const dateRanges = computed(() => orderBy(store.getters.dateRanges, 'id'));

    const dropdownOpened = ref(false);
    const selectDateRange = (dateRange) => {
      dropdownOpened.value = false;
      if (dateRange.id === selectedDateRange.value.id) return;
      store.dispatch('selectDateRange', dateRange);
    };
    const dateRangeRef = ref(false);
    onClickOutside(dateRangeRef, () => { dropdownOpened.value = false; });

    const container = ref(null);
    const stickyArea = ref(null);
    const groupHeaders = ref([]);
    const groupHeadersPinned = ref([]);
    const updateGroupHeaders = () => {
      const html = document.querySelector('html');
      const isScrollable = html.scrollHeight > window.innerHeight;
      const pinSensitivity = isMobile.value ? PIN_SENSITIVITY_MOBILE : PIN_SENSITIVITY_DESKTOP;
      groupHeadersPinned.value = [];

      if (!isMobile.value && !isScrollable) return;

      groupHeaders.value.forEach((header, index) => {
        const groupTop = header.parentNode.offsetTop - pinSensitivity;
        const shouldBePinned = html.scrollTop > 0 && (html.scrollTop - groupTop + header.offsetHeight) >= 0;
        groupHeadersPinned.value[index] = shouldBePinned;
      });
    };

    const selectedSportId = computed(() => store.getters.selectedSportId);
    const selectedCompetitionId = computed(() => store.getters.selectedCompetitionId);
    const scrollToTop = () => {
      updateGroupHeaders();
      const html = document.querySelector('html');
      html.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    watch(
      [selectedSportId, selectedCompetitionId],
      scrollToTop,
    );

    onMounted(() => {
      window.addEventListener('scroll', updateGroupHeaders);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', updateGroupHeaders);
    });

    return {
      isMobile,
      loading,
      searchEvents,
      search,
      searchQuery,
      liveToggled,
      toggleLiveFilter,
      groupedEventsByDate,
      selectedDateRange,
      dateRanges,
      dateRangeRef,
      selectDateRange,
      dropdownOpened,
      showMenu,
      stickyArea,
      container,
      groupHeaders,
      groupHeadersPinned,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -0.875rem;
}

.event-list__group {
  position: relative;
  margin-top: -0.375rem;
  padding-bottom: 1rem;
}

.event-list__group-header {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #FAFAFA;
  position: sticky;
  top: 3.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0;
  height: 2.0625rem;
  padding: 0.5rem 1rem;
  transition: top 0.6s ease;

  &.pinned::before {
    background-color: #FAFAFA;
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 150%;
    content: '';
    z-index: -1;
    transition: background-color 0.6s ease;
  }

  &.pinned:not(.event-list__group-header--expanded) {
    background-color: #fff;
    box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12) !important;
    border-bottom: 1px solid #F0F0F0;

    &::before {
      background-color: #fff;
    }
  }

  &.event-list__group-header--expanded {
    top: 10.5rem;
  }
}

.event-list__content {
  display: flex;
  flex-direction: column;
  margin-top: calc(10rem + 0.75rem + 1.25rem);
  min-height: 650px;

  &.event-list__content--loading {
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 120px;
  }

  &.event-list__content--empty {
    flex: 1;
    align-items: center;
    justify-content: center;
    color: #A9A9A9;
    font-size: 1rem;
    gap: 0;
    width: 25rem;
    margin: 0 auto;
    padding: 0 1rem;

    svg {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
}

.event-list__content-message {
  text-align: center;
  position: relative;
  top: 3rem;
}

.event-list.event-list--desktop {
  .event-list__content {
    margin-top: 1rem;

    &.event-list__content--empty {
      justify-content: flex-start;
      position: relative;
      top: -1.5rem;
      z-index: 500;
      padding: 0;
    }
  }

  .event-list__group-header {
    height: 2.1875rem;
    background-color: #FAFAFA;
    font-size: 1rem;
    top: 9.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;

    &::before {
      background-color: #FAFAFA;
      top: 0;
      left: -1%;
      width: 102%;
      height: 100%;
    }
  }
}
</style>
